.messages-section {
  margin-top: 20px;
  .message-body {
    height: 400px;
    overflow-y: auto;
    padding: 20px;
    margin-right: 20px;
    .message-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &.left-side {
        justify-content: flex-start;
      }
      .message-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        max-width: 60%;
        width: max-content;
        border-radius: 0;
        border-left: 3px solid #006753;
        .card-body {
          padding: 0.5rem 1rem;
        }
        .profile {
          img {
            width: 30px;
          }
        }
        .user-name {
          font-size: 1rem;
          font-weight: bold;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 0;
        }
        .message-container {
          margin-left: 40px;
          margin-right: 40px;
          p {
            font-size: 0.8rem;
            margin-bottom: 0;
          }
        }

        &.right-side-message {
          border-left: 0;
          border-right: 3px solid #4f46e5;

          .message-card {
            flex-direction: row-reverse;
            .message-container {
              p {
                text-align: right !important;
              }
            }
          }
        }
      }
    }
  }
}

.pe-8 {
  margin-right: 40px;
  margin-bottom: 0;
}
.ps-8 {
  margin-left: 40px;
  margin-bottom: 0;
}

.loading {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.conversation-body {
  max-height: 600px;
  overflow-y: auto;
}
.conversation-card {
  margin-bottom: 10px;
  cursor: pointer;
  .card-body {
    justify-content: space-between;
    .profile-holder {
      align-items: center;
    }
  }
  .profile-pic {
    height: 40px;
    width: 40px;
    p {
      margin-bottom: 0;
    }
    margin-right: 20px;
  }
}

.floating-icon {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 100px;
  right: 40px;
  background-color: #016753;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0px 0px 5px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
