//
// helper.scss
//

/*********************************/
/*         Helper                */
/*===============================*/
.section {
    padding: 100px 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
}

.section-two {
    padding: 48px 0;
    position: relative;
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-linear-gradient {
    background: $linear-gradient;
}

.bg-linear-gradient-2 {
    background: $linear-gradient-2;
}

.bg-gradient-overlay {
    background: $gradient-overlay;
}

.bg-gradient-overlay-2 {
    background: $gradient-overlay-2;
}

.bg-primary-gradient-overlay {
    background: $primary-gradient-overlay;
}

/*********************************/
/*         Title CSS             */
/*===============================*/
.title-heading {
    line-height: 26px;
    .heading {
        font-size: 48px !important;
        letter-spacing: 1px;
        @media (max-width: 768px) {
            font-size: 40px !important;
        }

        &.sub-heading {
            font-size: 30px !important;
            @media (max-width: 768px) {
                font-size: 26px !important;
            }
        }
    }
    .letter-spacing {
        letter-spacing: 1px;
    }
    .para-desc {
        font-size: 17px;
        letter-spacing: 0.5px;
        @media (max-width: 768px) {
            font-size: 16px !important;
        }
    }
}

.section-title {
    position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 28px !important;
        @media (max-width: 768px) {
            font-size: 24px !important;
        }
    }
} 

.para-desc {
    max-width: 600px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}

.pt-150 {
    padding-top: 150px !important;
}

/*********************************/
/*         Shapes                */
/*===============================*/
.shape {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    @media (max-width: 425px) {
        bottom: -4px;
    }
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}

@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

.features-absolute {
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
}

.features-absolute {
    margin: -200px 0 0px;

    @media (max-width: 768px) {
        margin: -140px 0 0;
    }
    
    &.subscribe-search {
        margin: -120px 0 0px;

        @media (max-width: 768px) {
            margin: -90px 0 0px;
        }
    }
}

/*********************************/
/*         Video iframe          */
/*===============================*/
@media (max-width: 640px) {
    [data-type] iframe, [data-type] video {
        width: 500px;
        height: 300px;
    }
}
@media (max-width: 425px) {
    [data-type] iframe, [data-type] video {
        width: 360px;
        height: 260px;
    }
}
@media (max-width: 375px) {
    [data-type] iframe, [data-type] video {
        width: 100%;
        height: auto;
    }
}

/*********************************/
/*         Tobii Lightbox          */
/*===============================*/
.tobii__btn svg,
.tobii>button.tobii__close svg {
    height: 30px;
    width: auto;
}
.tobii__counter {
    font-size: 16px;
}
.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: 0 !important;
}

/*********************************/
/*         Edit Profile          */
/*===============================*/
.profile-pic,
.profile-banner {
    position: relative;
    color: transparent;
    input {
        display: none;
    }
    
    .icons {
        cursor: pointer;
        z-index: 1;
    }
}
.profile-banner {
    img {
        width: 1200px;
        height: 200px;
    }
    .profile-image-label {
        position: absolute;
        inset: 0;
        cursor: pointer;
    }
}

.profile-edit {
    .form-control {
        border-bottom: 1px solid $gray-200 !important;
        border-radius: 0;
        border: 0;
        padding: 10px 0;
        font-size: 16px;
        font-weight: 400;
    }
}

.preview-box {
    img {
        max-width: 100%;
        height: auto;
        border-radius: 6px;
    }
}

/*********************************/
/*         Switcher pricing      */
/*===============================*/
.switcher-pricing {
    .toggle, .toggler {
        display: inline-block;
        vertical-align: middle;
    }
    
    .toggler {
        transition: all 0.5s ease;
        font-weight: bold;
    }
    
    .toggler--is-active {
        color: $primary !important;
    }

    .form-switch {
        .form-check-input {
            width: 48px;
            height: 24px;
            margin-top: 0;
        }
    }
}

.hide{
    display: none;
}
ul li{
    -webkit-margin-start: 0.25rem;
            margin-inline-start: 0.25rem;
  }
//   ul li:first-child{
//     -webkit-margin-start: 0px;
//             margin-inline-start: 0px;
//   }
  /*Select 2*/ 
.css-13cymwt-control{
    border-color: transparent !important;
    border-style: none !important;
    background-color: $light !important;
    height: 60px !important;
  }
  
  .css-t3ipsp-control {
    border-color: transparent !important;
    background-color: $light !important;
    height: 60px !important;
  }
  
  .css-1u9des2-indicatorSeparator{
    display: none !important;
  }
  
  .css-1fdsijx-ValueContainer{
    padding: 0px !important;
  }
  
  .css-qbdosj-Input {
    margin: 0 !important;
    padding: 0px !important;
  }
  
  .css-13cymwt-control:hover,
  .css-13cymwt-control:focus,
  .css-t3ipsp-control:hover,
  .css-t3ipsp-control:focus{
    border-color: transparent !important;
    border-style: none !important;
    box-shadow: none !important;
    background-color: $light !important;
  }
  
  .css-1dimb5e-singleValue{
    color: $dark !important;
  }
  
  .css-1nmdiq5-menu{
    z-index: 999;
    border-radius: 5px;
    border-style: none !important;
    background-color: $white !important;
    box-shadow: $shadow;
    color: $dark !important;
  }
  .css-1nmdiq5-menu .css-1n6sfyn-MenuList::-webkit-scrollbar {
    display: none;
  }
  
  .css-13cymwt-control:after,
  .css-13cymwt-control:before{
    box-shadow: $shadow !important;
  }
  .css-1jqq78o-placeholder {
    margin-left: 48px !important;
}


/*Select 2*/ 
.css-13cymwt-control{
    background-color: $light !important;
}
.css-1dimb5e-singleValue{
    color: $dark !important;
  }
  .css-13cymwt-control:hover,
  .css-13cymwt-control:focus,
  .css-t3ipsp-control:hover,
  .css-t3ipsp-control:focus{
    background-color: $light !important;
  }