.split_sec {
    display: flex;
    flex-wrap: wrap;

    h6 {
        margin-right: 15px;
        display: flex;
        align-items: center;


        span {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $primary;
            display: block;
            margin-right: 10px;
        }


    }
}

.form_ex {
    font-size: 16px;
    cursor: pointer;

    svg {
        margin-left: 10px;
        color: $primary;
    }
}